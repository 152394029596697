<template>
    <BT-Blade
        :canClose="false"
        :canDelete="false"
        :canEdit="false"
        :canRefresh="false"
        hideBackButton
        title="Supplier Dashboard">
        <template v-slot>
            <v-container>
                <v-row>
                    <v-col cols="12" md="4">
                        <BT-Graph-Card
                            defaultChartType="Line"
                            defaultGraphType="Gradual"
                            defaultXSpan="This Week"
                            defaultXSpread="Total Count"
                            hideHorizontals
                            hideLegend
                            hideXLabels
                            hideYLabels
                            navigation="customers"
                            title="New Customers"
                            wholeNumbers />
                    </v-col>
                    <!-- <v-col cols="12" md="4">
                        <BT-Graph-Card title="Pending Orders" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <BT-Graph-Card title="Unpaid" />
                    </v-col> -->
                </v-row>
            </v-container>
        </template>
    </BT-Blade>
</template>

<script>
export default {
    name: 'Supplier-Dashboard',
    components: {
        BTGraphCard: () => import('~components/BT-Graph-Card.vue')
    }
}
</script>